<template>
  <v-list-item @click="addCombined">
    <v-list-item-avatar size="75">
      <base-img
        cover
        :class="`rounded-lg ${
          combined.qty_existence === 0 ? 'opaque_img' : ''
        }`"
        max-width="5rem"
        :aspect-ratio="1"
        :src="combined.image || require('@/assets/no-image.png')"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <h4>{{ combined.name }}</h4>
      <v-list-item-subtitle class="primary--text text--lighten-1">
        +{{ combined.price }} €
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="combined.qty_existence === 0"
        class="text--lighten-1"
      >
        {{ $t("outOfStock") }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="isSelected">
      <v-icon x-large color="success" rounded>mdi-check</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { combinedStoreGetters } from "@/store/modules/combined/constNames";

export default {
  name: "ListItemCombined",
  props: ["combined"],
  methods: {
    addCombined() {
      if (this.combined.qty_existence === 0) {
        return;
      }
      this.combined.qty = 1;
      this.combined.type = "combinado";
      this.$emit("selectCombined", this.combined);
    },
  },
  computed: {
    isSelected() {
      return this.$store.getters[combinedStoreGetters.GET_COMBINED].some(
        ({ id }) => this.combined.id === id
      );
    },
  },
};
</script>

<style scoped>
.opaque_img {
  opacity: 0.3;
}
</style>
